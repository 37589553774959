/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Box,
  Button,
  Text,
  useToast
} from '@chakra-ui/react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import * as amplitude from '@amplitude/analytics-browser'

import { BASE_URL } from '../../config'
import { Project } from '../../_helpers/types'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../../firebase'

interface CompleteProjectProps {
  isOpen: boolean
  onClose: () => void
  project: Project
}

const CompleteProject = (props: CompleteProjectProps) => {
  const [user] = useAuthState(auth)
  const toast = useToast()
  const navigate = useNavigate()
  const [, setIsLoadingCompleteProject] = useState(false)

  const handleCompleteProject = async () => {
    try {
      setIsLoadingCompleteProject(true)

      await axios.put(`${BASE_URL}/projects/${props.project.id}`, {
        isComplete: true
      })

      toast({
        title: 'Success!',
        description: 'Your project has been completed!',
        status: 'success',
        duration: 5000,
        isClosable: true
      })

      amplitude.track('Project Completed', {
        projectID: props.project.id,
        userID: user?.uid
      })
    } catch (error) {
      toast({
        title: 'Error',
        description: 'There was an error in submitting your project. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    } finally {
      setIsLoadingCompleteProject(false)
      navigate('/projects')
    }
  }

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Complete Project</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box mb={4} p={4} bg='gray.100' borderRadius='lg' boxShadow='sm'>
            <Text mb={2} fontWeight='bold'>
              Summary
            </Text>
            <ul style={{ marginLeft: '3em' }}>
              <li>
                <Text>Project Name: {props.project.name}</Text>
              </li>
              <li>
                <Text>Number of Tracks: {props.project.numTracks}</Text>
              </li>
            </ul>
          </Box>
          <Button mb = {2}
            onClick={handleCompleteProject}
            colorScheme='green'
            fontSize='xl'
            variant='outline'
            _hover={{ color: 'white', bg: 'green' }}
            size='md'
          >
            Submit
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default CompleteProject
