import React, { useState } from 'react';
import { Box, Flex, VStack, Text, Button, AspectRatio, Heading, Container, HStack, Image } from '@chakra-ui/react';

interface Feature {
  title: string;
  description: string;
  hook: string;
  videoUrl: string;
}

const features: Feature[] = [
  {
    title: "Time-Stamped Comments",
    description: "Drop precise comments directly on the audio file.",
    hook: "Skip the confusion, tackle issues separately",
    videoUrl: "/timestamped_commenting_feature.mp4"
  },
  {
    title: "Project Management",
    description: "Projects, tracks, & collaborators - all in one place.",
    hook: "No more digging through emails and folders",
    videoUrl: "/project_management_feature.mp4"
  },
  {
    title: "AI Suggestion Generator",
    description: "Translate your ideas into actionable suggestions.",
    hook: "It's like having an audio wizard on your team",
    videoUrl: "/ai_suggestion_feature.mp4"
  },
  {
    title: "Version Control System",
    description: "Easily compare versions and track your progress.",
    hook: "No more 'final_v3_REALLY_FINAL.mp3'",
    videoUrl: "/version_control_feature.mp4"
  }
];

const FeatureTabs: React.FC = () => {
  const [activeTab, setActiveTab] = useState<number>(0);

  return (
    <Box py={20} bg = {'orange.50'}>
      <Container maxW="container.xl">
        <Heading as='h2' size='2xl' textAlign='center' mb={16}>
          Get More Done. Faster.
        </Heading>
        <Flex borderRadius="lg" height="550px">
          <VStack 
            align="stretch" 
            width="35%" 
            spacing={4} 
            pr={4} 
            height="100%"
          >
            {features.map((feature, index) => (
              <Button
                key={index}
                onClick={() => setActiveTab(index)}
                variant={activeTab === index ? "solid" : "ghost"}
                colorScheme="orange"
                justifyContent="flex-start"
                alignItems="flex-start"
                height="auto"
                py={4}
                px={4}
                borderRadius="md"
                _active={{ bg: 'orange.200' }}
                _hover={activeTab !== index ? { bg: 'orange.100' } : {}}
                textAlign="left"
                flex={1}
                boxShadow= {'md'}
              >
                <VStack align="start" spacing={1} width="100%">
                  <Text fontWeight="bold" fontSize="xl" width="100%">
                    {feature.title}
                  </Text>
                  <Text fontSize="md" color={activeTab === index ? "white" : "gray.600"} width="100%" whiteSpace="normal" fontWeight='medium'>
                    {feature.description}
                  </Text>
                  <Text fontSize="md" color={activeTab === index ? "white" : "gray.600"} width="100%" whiteSpace="normal" fontWeight='medium'>
                    {feature.hook}
                  </Text>
                </VStack>
              </Button>
            ))}
          </VStack>
          <Box width="65%" bg="orange.800" borderRadius="lg" overflow="hidden" boxShadow={'md'}>
            <AspectRatio ratio={16 / 9} height="100%">
              <video
                key={features[activeTab].videoUrl}
                src={features[activeTab].videoUrl}
                controls
                width="100%"
                height="100%"
                style={{ objectFit: 'cover' }}
                loop = {true}
                autoPlay = {true}
                muted={true}
                playsInline={true}
                
              />
            </AspectRatio>
          </Box>
        </Flex>
      </Container>
      
      <Box p = {6} mt = {16}>
      <Container maxW="container.xl">
        <HStack flexWrap="wrap" justifyContent="space-evenly">
          <Box bg="white" p={6} borderRadius="md" boxShadow="md" width={{ base: "full", md: "45%" }}>
            <HStack spacing={6} justifyContent={'center'}>
              <Text fontSize='xl' fontWeight="medium" color="black">
                Faster Communication Than
              </Text>
              <HStack spacing={6}>
                <Image src="/whatsapp.png" alt="WhatsApp" boxSize="40px" objectFit='contain' />
                <Image src="/imessage.png" alt="iMessage" boxSize="40px" objectFit='scale-down' />
                <Image src="/gmail.png" alt="Gmail" boxSize="40px" objectFit="contain" />
              </HStack>
            </HStack>
          </Box>
          
          <Box bg="white" p={6} borderRadius="md" boxShadow="md" width={{ base: "full", md: "45%" }}>
            <HStack spacing={6} justifyContent={'center'}>
              <Text fontSize= 'xl' fontWeight="medium" color="black">
                Easier File Sharing Than
              </Text>
              <HStack spacing={6}>
                <Image src="/googledrive.png" alt="Google Drive" boxSize="40px" objectFit="contain" />
                <Image src="/soundcloud.png" alt="SoundCloud" boxSize="40px" objectFit="contain" />
                <Image src="/wetransfer.png" alt="WeTransfer" boxSize="40px" objectFit="contain" />
              </HStack>
            </HStack>
          </Box>
        </HStack>
      </Container>
    </Box>
    </Box>
  );
};

export default FeatureTabs;