import React, { useEffect, useState } from 'react'
import { Box, Flex, Text, Button, useDisclosure, useColorModeValue, Icon, HStack, Menu, MenuItem, MenuList, IconButton, MenuButton, useToast } from '@chakra-ui/react'
import { FaFolder, FaPlus, FaEdit, FaEllipsisV, FaTrash } from 'react-icons/fa'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../../firebase'
import { useNavigate } from 'react-router-dom'
import ReactGA from 'react-ga4'

import LoadingScreen from '../common/LoadingScreen'
import ErrorScreen from '../common/ErrorScreen'
import { Project } from '../../_helpers/types'
import CreateProject from './CreateProject'
import RenameProject from './RenameProject'
import { BASE_URL } from '../../config'
import axios from 'axios'

const ProjectsHome: React.FC<{ setShowSidebar: (show: boolean) => void }> = ({
  setShowSidebar
}) => {
  ReactGA.send({
    hitType: 'pageview',
    page: window.location.pathname,
    name: 'Projects Home'
  })

  const [user, loading, error] = useAuthState(auth)
  const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const projectHoverBgColor = useColorModeValue('orange.200', 'gray.700')
  const toast = useToast()

  const [projectsData, setProjectsData] = useState<Project[]>([])
  const [projectsLoading, setProjectsLoading] = useState(false)
  const [projectsError, setProjectsError] = useState<Error | null>(null)
  const [filter, setFilter] = useState(false)
  const [filterStatus, setFilterStatus] = useState(false)
  const [selectedProject, setSelectedProject] = useState<Project | null>(null)
  const { isOpen: isRenameOpen, onOpen: onRenameOpen, onClose: onRenameClose } = useDisclosure()

  useEffect(() => {
    setShowSidebar(true)
  }, [])

  useEffect(() => {
    if (!error && user?.uid) {
      setProjectsLoading(true)
      axios
        .get(`${BASE_URL}/projects?userID=${user.uid}`)
        .then((response: { data: React.SetStateAction<Project[]> }) => {
          setProjectsData(response.data)
          setProjectsLoading(false)
        })
        .catch((error) => {
          setProjectsError(error)
          setProjectsLoading(false)
        })
    }
  }, [user?.uid])

  if (loading || projectsLoading) {
    return <LoadingScreen />
  }

  if (!user) {
    const currentPath = `/projects`
    navigate(`/login?returnUrl=${encodeURIComponent(currentPath)}`)
  }

  if (error || projectsError) {
    return <ErrorScreen error={(error || projectsError) as Error} />
  }

  const handleFilter = (newFilter: boolean) => {
    if (filterStatus == false) {
      setFilterStatus(true)
      setFilter(newFilter)
    } else if (filterStatus == true) {
      if (filter == newFilter) {
        setFilterStatus(false)
      } else {
        setFilter(newFilter)
      }
    }
  }

  const handleDeleteProject = async (project: Project) => {
    if (window.confirm(`Are you sure you want to delete the project "${project.name}"?`)) {
      try {
        await axios.delete(`${BASE_URL}/projects/${project.id}`)
        setProjectsData(prevProjects => prevProjects.filter(p => p.id !== project.id))
        toast({
          title: "Project deleted",
          description: `The project "${project.name}" has been successfully deleted.`,
          status: "success",
          duration: 5000,
          isClosable: true,
        })
        window.location.reload()
      } catch (error) {
        console.error("Error deleting project:", error)
        toast({
          title: "Error",
          description: "An error occurred while deleting the project. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        })
      }
    }
  }

  return (
    <Flex>
      <Box bg='beige' minHeight='100vh' color='black' flex='1'>
        <Flex direction='column' margin='0 auto' p={8}>
          <Flex justify='space-between' align='center' mb={6}>
            <Flex align='center'>
              <Text fontSize='2xl' fontWeight='bold'>
                Library
              </Text>
            </Flex>
          </Flex>

          <Flex mb={6}>
            {/* Add more informative views here based on customer feedback */}
          </Flex>

          <Flex justifyContent='space-between'>
            <Flex>
              <Button colorScheme='orange' mr={2} onClick={onOpen}>
                Create
              </Button>
            </Flex>
            <HStack mb={4} justifyContent={'flex-end'}>
              <Text>Filter By:</Text>
              <Button
                bgColor={!filter && filterStatus ? 'red.400' : 'orange.100'}
                borderRadius='md'
                _hover={{
                  bg: !filter && filterStatus ? '' : 'orange.200',
                  cursor: 'pointer'
                }}
                onClick={() => handleFilter(false)}
              >
                Active
              </Button>
              <Button
                bgColor={filter && filterStatus ? 'green.500' : 'orange.100'}
                borderRadius='md'
                _hover={{
                  bg: filter && filterStatus ? '' : 'orange.200',
                  cursor: 'pointer'
                }}
                onClick={() => handleFilter(true)}
              >
                Completed
              </Button>
            </HStack>
          </Flex>

          {projectsData &&
            projectsData
              .filter((project: Project) => {
                if (filterStatus) {
                  return filter ? project.isComplete : !project.isComplete
                } else {
                  return true
                }
              })
              .sort((a: Project, b: Project) => {
                const dateA = new Date(a.createdAt)
                const dateB = new Date(b.createdAt)
                return dateA.getTime() - dateB.getTime()
              })
              .reverse()
              .map((project: Project) => (
                <Flex
                  key={project.id}
                  bg='orange.100'
                  p={4}
                  borderRadius='md'
                  mb={4}
                  align='center'
                  _hover={{ bg: 'orange.200', cursor: 'pointer' }}
                  onClick={() => navigate(`/projects/${project.id}`)}
                >
                  <Icon as={FaFolder} boxSize={4} mr={4} />
                  <Box width='100%'>
                    <HStack justifyContent={'space-between'}>
                      <Text fontWeight='bold'>{project.name}</Text>

                      <HStack>
                        <Box p={1} rounded={4}>
                          <Text
                            color={project.isComplete ? 'green.500' : 'red.400'}
                            fontSize='sm'
                            textAlign={'center'}
                            fontWeight={'bold'}
                          >
                            {project.isComplete ? 'Completed' : 'Active'}
                          </Text>
                        </Box>

                        <Menu>
                          <MenuButton
                            as={IconButton}
                            icon={<FaEllipsisV />}
                            variant='ghost'
                            aria-label='Project Options'
                            borderRadius='full'
                            _hover={{ bg: 'orange.400' }}
                            _active={{ bg: projectHoverBgColor }}
                            onClick={(e) => {
                              e.stopPropagation()
                            }}
                          />
                          <MenuList>
                            <MenuItem
                              icon={<FaEdit />}
                              onClick={(e) => {
                                e.stopPropagation()
                                setSelectedProject(project)
                                onRenameOpen()
                              }}
                            >
                              Rename Project
                            </MenuItem>
                            <MenuItem
                              icon={<FaTrash />}
                              onClick={(e) => {
                                e.stopPropagation()
                                handleDeleteProject(project)
                              }}
                            >
                              Delete Project
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </HStack>
                    </HStack>
                    <Text color='gray.600' fontSize='sm' mt={2}>
                      Created on {project.createdAt.split('T')[0]} by{' '}
                      {user?.email == project.createdBy ? 'you' : project.createdBy}
                    </Text>
                  </Box>
                </Flex>
              ))}

          <HStack justifyContent={'space-between'}>
            <Button
              leftIcon={<FaPlus />}
              colorScheme='orange'
              variant='ghost'
              onClick={onOpen}
              alignSelf='flex-start'
            >
              New Project
            </Button>
            <Text color='gray.600'>
              {projectsData
                ? filterStatus
                  ? projectsData.filter((project: Project) =>
                      filter ? project.isComplete : !project.isComplete
                    ).length
                  : projectsData.length
                : 0}{' '}
              Results
            </Text>
          </HStack>
        </Flex>

        <CreateProject isOpen={isOpen} onClose={onClose} />
        {selectedProject && (
          <RenameProject
            project={selectedProject}
            isOpen={isRenameOpen}
            onClose={() => {
              onRenameClose()
              setSelectedProject(null)
            }}
          />
        )}
      </Box>
    </Flex>
  )
}

export default ProjectsHome