/* eslint-disable react/prop-types */
import React, { useState, useRef, useEffect } from 'react'
import {
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Flex,
  ModalFooter,
  Button,
  Input,
  Text,
  Box,
  HStack,
  Spinner,
  IconButton,
  Textarea,
  VStack,
  Icon,
  keyframes,
  Tooltip
} from '@chakra-ui/react'
import { CheckIcon } from '@chakra-ui/icons'
import axios from 'axios'
import { FaPaperPlane, FaMagic } from 'react-icons/fa'
import { motion } from 'framer-motion'
import { useAuthState } from 'react-firebase-hooks/auth'
import * as amplitude from '@amplitude/analytics-browser'

import { auth, db } from '../firebase'
import { doc, onSnapshot, updateDoc, addDoc, getDoc, collection, query, where, getDocs, writeBatch } from 'firebase/firestore' // TODO: Change to axios calls to backend and add db update methods
import { BASE_URL } from '../config'
import { Role } from '../api/permission/permissions'

const ThreadModal = ({
  isOpen,
  onClose,
  versionID,
  region,
  threadParam,
  filePath,
  refetchThreads,
  isOwner,
  roles
}) => {
  const pulseAnimation = keyframes`
    0% { transform: scale(1) rotate(0); }
    12.5% { transform: scale(1.05) rotate(5deg); }
    25% { transform: scale(1) rotate(0); }
    37.5% { transform: scale(1.05) rotate(-5deg); }
    50% { transform: scale(1) rotate(0); }
    62.5% { transform: scale(1.05) rotate(-5deg); }
    75% { transform: scale(1) rotate(0); }
    87.5% { transform: scale(1.05) rotate(-5deg); }
    100% { transform: scale(1) rotate(0); }
  `

  const [user] = useAuthState(auth)
  const [thread, setThread] = useState(threadParam)
  const [messages, setMessages] = useState([])
  const [newMessage, setNewMessage] = useState('')
  const [aiResponse, setAIResponse] = useState([])
  const [aiLoading, setAILoading] = useState(false)
  const [lastUserMessage, setLastUserMessage] = useState('')
  const [hasChanges, setHasChanges] = useState(false)
  const timerRef = useRef(null)
  const toast = useToast()

  const modalBodyRef = useRef(null)

  useEffect(() => {
    if (modalBodyRef.current && messages.length > 0) {
      modalBodyRef.current.lastElementChild.scrollIntoView({ behavior: 'smooth' })
      modalBodyRef.current.scrollTop = modalBodyRef.current.scrollHeight
    }
  }, [messages, aiResponse])

  useEffect(() => {
    setThread(threadParam)
  }, [threadParam])

  useEffect(() => {
    if (aiResponse.length > 0) {
      timerRef.current = setTimeout(() => {
        setAILoading(false)
      }, 1000)
    }

    return () => clearTimeout(timerRef.current)
  }, [aiResponse])

  useEffect(() => {
    let unsubscribe;
  
    if (isOpen) {
      if (threadParam?.id) {
        // Existing thread
        const threadRef = doc(db, 'threads', threadParam.id);
        unsubscribe = onSnapshot(threadRef, (docSnapshot) => {
          if (docSnapshot.exists()) {
            const threadData = docSnapshot.data();
            setThread(prevThread => ({ ...prevThread, ...threadData }));
            setMessages(threadData.messages.map(message => ({
              ...message
            })));
          }
        });
      } else {
        // New thread
        setThread(null);
        setMessages([]);
      }
    }
  
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [isOpen, threadParam]);
  
  const updateOrCreateThread = async (newMessages) => {
    const messageBody = newMessages.map((message) => ({
      content: message.content,
      sender: message.sender,
      timestamp: message.timestamp.toLocaleString()
    }))

    const threadData = {
      messages: messageBody,
      versionID,
      isResolved: false,
      region: region
    }

    let updatedThread

    if (thread?.id) {
      const threadRef = doc(db, 'threads', thread.id);
      await updateDoc(threadRef, {
        messages: messageBody,
        isResolved: false
      });

      updatedThread = { ...thread, messages: messageBody, isResolved: false }

      amplitude.track('Thread Updated', {
        threadID: thread.id,
        userID: user?.uid || 'Invitee'
      });

    } else {
      const newThread = await axios.post(`${BASE_URL}/threads`, threadData);
      updatedThread = newThread.data;

      region.setOptions({
        color: 'rgba(0, 0, 255, 0.1)',
        resize: false,
        drag: false
      });

      amplitude.track('Thread Created', {
        versionID: versionID,
        userID: user?.uid || 'Invitee'
      });
    }

    setThread(updatedThread);
    setHasChanges(true);
    return updatedThread;
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (newMessage.trim() && !thread?.isResolved && canComment) {
      const newMessageObj = {
        content: newMessage,
        sender: user?.email || localStorage.getItem('userEmail') || 'Anonymous',
        timestamp: new Date()
      }

      const updatedMessages = [...messages, newMessageObj]
      setMessages(updatedMessages)
      setNewMessage('')

      try {
        const updatedThread = await updateOrCreateThread(updatedMessages);
        console.log('Updated thread:', updatedMessages);
        
        // Create notifications for all users except the sender
        const versionRef = doc(db, 'versions', versionID);
        const versionSnapshot = await getDoc(versionRef);
        const versionData = versionSnapshot.data();

        const trackID = versionData.trackID;
        const trackRef = doc(db, 'tracks', trackID);
        const trackSnapshot = await getDoc(trackRef);
        const trackData = trackSnapshot.data();

        const projectID = trackData.projectID;
        const projectRef = doc(db, 'projects', projectID);
        const projectSnapshot = await getDoc(projectRef);
        const projectData = projectSnapshot.data();

        if (projectData && projectData.userIDs) {
          const recipientIds = projectData.userIDs.filter(userID => userID !== user.uid);
          
          for (const recipientId of recipientIds) {
            await addDoc(collection(db, 'notifications'), {
              versionID: versionID,
              threadId: updatedThread.id,
              read: false,
              createdAt: new Date(),
              recipientId: recipientId,
              metaData: {senderEmail: user.email, projectName: projectData.name, trackName: trackData.name}
            });
          }
        }
        
      } catch (error) {
        console.error('Failed to send message:', error);
        toast({
          title: 'Failed to send message',
          status: 'error',
          duration: 3000,
          isClosable: true
        });
      }
    }
  };

  const handleAISuggestion = async () => {
    if (newMessage.trim()) {
      setAIResponse([])
      setLastUserMessage(newMessage)
      setAILoading(true)

      const req_body = `${newMessage} | Timestamp: ${region.start} - ${region.end}`

        // FOR DEMO PURPOSES ONLY ---->
        if (newMessage.includes('energy')) {
          const sampleResp = [
            'Would you be able to inject more energy by using a more upbeat tone and delivery for this segment?',
            'Can you increase the pace and infuse more enthusiasm into this section?',
            'Could you add more dynamic variation and liveliness to this part?'
          ]

          setAIResponse([...sampleResp])
          setAILoading(false)
          return
        } else if (newMessage.includes('pop')) {
          const sampleResp = [
            'Can you consider incorporating some upbeat music or sound effects to heighten the excitement of this segment?',
            'Could you consider varying the pacing or intensity in this part to create a more exciting atmosphere?',
            'I think adding more dynamic range to this part could make it more exciting.'
          ]

          setAIResponse([...sampleResp])
          setAILoading(false)
          return
        }
        // FOR DEMO PURPOSES ONLY <----

      try {
        const response = await axios.post(`${BASE_URL}/threads/ai`, {
          // TODO: make file analysis faster and figure out why filePath is undefined
          file_path: filePath || undefined,
          message: req_body
        })

        const rawResp = JSON.parse(response.data)

        // Parse the response (json format) to an array of strings
        const apiResp = rawResp.map((resp) => {
          return resp.message
        })

        setAIResponse([...apiResp])

        // Track the AI suggestion generated
        // TODO: Should we make this more specific (by thread, version, etc.)?
        amplitude.track('AI Suggestion Generated', {
          userID: user?.uid
        })
      } catch (error) {
        console.error('Error generating AI response:', error)
        toast({
          title: 'Failed to generate AI suggestions',
          status: 'error',
          duration: 3000,
          isClosable: true
        })
      } finally {
        setAILoading(false)
      }
    }
  }

  const handleAIUpdate = async (aiMsg) => {
    const updatedMessages = [
      ...messages,
      {
        content: aiMsg,
        sender: user?.email || localStorage.getItem('userEmail') || 'Anonymous',
        timestamp: new Date()
      }
    ]
    setMessages(updatedMessages)
    setNewMessage('')
    setAIResponse([])

    // Track the AI suggestion used
    // TODO: Should we make this more specific (by thread, version, etc.)?
    amplitude.track('AI Suggestion Used', {
      userID: user?.uid
    })

    // Update the thread with the new AI message
    await updateOrCreateThread(updatedMessages)
  }

  useEffect(() => {
    if (isOpen && thread?.id && user) {
      markRelatedNotificationsAsRead();
    }
  }, [isOpen, thread?.id, user]);

  const markRelatedNotificationsAsRead = async () => {
    if (!user) return;

    const notificationsRef = collection(db, 'notifications');
    const q = query(
      notificationsRef,
      where('threadId', '==', thread.id),
      where('recipientId', '==', user.uid),
      where('read', '==', false)
    );

    try {
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const batch = writeBatch(db);
        querySnapshot.forEach((doc) => {
          batch.update(doc.ref, { read: true });
        });
        await batch.commit();
      }
    } catch (error) {
      console.error('Error marking notifications as read:', error);
    }
  };

  const handleResolveComment = async () => {
    if (thread) {
      try {
        await axios.put(`${BASE_URL}/threads/${thread.id}`, { isResolved: true })
        toast({
          title: 'Thread resolved!',
          status: 'success',
          duration: 3000,
          isClosable: true
        })

        amplitude.track('Thread Resolved', {
          threadID: thread.id,
          userID: user?.uid
        })

        // TODO: make this not a refresh
        window.location.reload()
      } catch (error) {
        console.error('Failed to resolve thread:', error)
        toast({
          title: 'Failed to resolve thread!',
          status: 'error',
          duration: 3000,
          isClosable: true
        })
      } finally {
        handleClose()
      }
    }
  }

  const handleClose = () => {
    if ((messages == null || messages.length === 0) && thread == null) {
      setMessages([])
      region.remove()
    }
    setNewMessage('')
    setAIResponse([])
    if (hasChanges) {
      refetchThreads()
    }
    setHasChanges(false)
    onClose()
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      handleSendMessage(e)
    }
  }

  const canComment = roles.length > 0 || isOwner

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size='xl'>
      <ModalOverlay />
      <ModalContent bg='white' color='gray.800' minWidth='70vw' maxHeight='80vh'>
        <ModalHeader>
          <Flex justifyContent='space-between' alignItems='center'>
            <Text fontSize='2xl' fontWeight='bold'>
              Chat
            </Text>
            <Flex alignItems='center'>
              {!thread?.isResolved && (isOwner || roles.includes(Role.MANAGE)) && (
                <Button
                  size='sm'
                  variant='outline'
                  colorScheme='green'
                  onClick={handleResolveComment}
                  leftIcon={<CheckIcon />}
                  mr={2}
                >
                  Resolve
                </Button>
              )}
              <ModalCloseButton position='static' />
            </Flex>
          </Flex>
        </ModalHeader>
        <ModalBody overflowY='auto' ref={modalBodyRef}>
          <VStack spacing={4} align='stretch'>
            {messages.map((message, index) => {
              const isUserMessage =
                message.sender ===
                (user?.email || localStorage.getItem('userEmail') || 'Anonymous')
              return (
                <Flex
                  key={index}
                  justifyContent={isUserMessage ? 'flex-end' : 'flex-start'}
                >
                  <Box
                    maxWidth='70%'
                    bg={isUserMessage ? 'blue.500' : 'gray.100'}
                    color={isUserMessage ? 'white' : 'black'}
                    p={3}
                    borderRadius='lg'
                    borderTopRightRadius={isUserMessage ? 0 : 'lg'}
                    borderTopLeftRadius={isUserMessage ? 'lg' : 0}
                  >
                    {!isUserMessage && <Text fontWeight='bold'>{message.sender}</Text>}
                    <Text
                      fontSize='sm'
                      color={isUserMessage ? 'blue.100' : 'gray.500'}
                      mb={1}
                    >
                      {message.timestamp.toLocaleString()}
                    </Text>
                    <Text>{message.content}</Text>
                  </Box>
                </Flex>
              )
            })}
          </VStack>
          {aiResponse.length !== 0 && (
            <Box justifySelf='center'>
              {aiLoading ? (
                <Spinner thickness='3px' color='orange.500' size='lg' />
              ) : (
                <VStack align='stretch' spacing={3}>
                  <Text fontSize='lg' fontWeight='bold'>
                    Select a response that best matches your feedback:
                  </Text>
                  <HStack spacing={4} alignItems='stretch' overflowX='auto' pb={2}>
                    <AIResponseBox
                      response={lastUserMessage}
                      handleAIUpdate={handleAIUpdate}
                      aiGenerated={false}
                    />
                    {aiResponse.map((response, index) => (
                      <AIResponseBox
                        key={index}
                        response={response}
                        handleAIUpdate={handleAIUpdate}
                        aiGenerated={true}
                      />
                    ))}
                  </HStack>
                </VStack>
              )}
            </Box>
          )}
        </ModalBody>
        <ModalFooter>
          <HStack width='100%' spacing={2}>
            <Input
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              onKeyUp={handleKeyPress}
              placeholder={
                thread?.isResolved
                  ? 'Thread is resolved'
                  : !canComment
                  ? 'You do not have permission to send messages'
                  : 'Type your message...'
              }
              flex={1}
              isDisabled={thread?.isResolved || !canComment}
            />
            <Tooltip label='Translate your ideas into actionable suggestions - try it out now!' hasArrow>
              <Button
                as={motion.button}
                animation={`${pulseAnimation} 5s`}
                onClick={handleAISuggestion}
                colorScheme='orange'
                leftIcon={<Icon as={FaMagic} />}
                isDisabled={thread?.isResolved || !newMessage.trim()}
              >
                Generate Suggestions
              </Button>
            </Tooltip>
            <IconButton
              icon={<FaPaperPlane />}
              colorScheme='blue'
              onClick={handleSendMessage}
              isDisabled={thread?.isResolved || !newMessage.trim()}
            />
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

const AIResponseBox = ({ response, handleAIUpdate, aiGenerated }) => {
  const [editResponse, setEditResponse] = useState(response)
  const textareaRef = useRef(null)

  const handleSaveClick = () => {
    handleAIUpdate(editResponse)
  }

  const textAreaChange = (e) => {
    setEditResponse(e.target.value)
    adjustTextareaHeight()
  }

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current
    if (textarea) {
      textarea.style.height = 'auto'
      textarea.style.height = textarea.scrollHeight + 'px'
    }
  }

  return (
    <Box
      bg={aiGenerated ? 'orange.100' : 'gray.100'}
      p={3}
      borderRadius='lg'
      borderTopLeftRadius={0}
      minWidth='200px'
      maxWidth='300px'
      flexShrink={0}
    >
      <Text fontWeight='bold' mb={1}>
        {aiGenerated ? 'AI Suggestion' : 'Original Message'}
      </Text>
      <Text fontSize='sm' color='gray.500' mb={2}>
        {new Date().toLocaleString()}
      </Text>
      <Textarea
        ref={textareaRef}
        value={editResponse}
        onChange={textAreaChange}
        onInput={adjustTextareaHeight}
        size='sm'
        variant='filled'
        resize='none'
        minH='100px'
        mb={2}
        bg='white'
      />
      <Flex justifyContent='flex-end'>
        <IconButton
          icon={<CheckIcon />}
          size='sm'
          colorScheme='green'
          onClick={handleSaveClick}
        />
      </Flex>
    </Box>
  )
}

export default ThreadModal
