// TODO: Duplicate code with ThreadModal.tsx, refactor to use a common component for both timestamp comments and general comments

import React, { useState, useEffect, useRef, useCallback } from 'react'
import {
  Box,
  Button,
  Flex,
  Heading,
  VStack,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Input,
  useDisclosure,
  useColorModeValue,
  Text,
  IconButton,
  HStack,
  Spinner,
  Icon,
  Textarea,
  useToast,
  Tooltip,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Portal
} from '@chakra-ui/react'
import {
  FaComment,
  FaPaperPlane,
  FaMagic,
  FaSave,
  FaCheckCircle,
  FaCommentDots
} from 'react-icons/fa'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth, storage, db } from '../firebase'
import { getDownloadURL, ref } from 'firebase/storage'
import { doc, updateDoc, onSnapshot, arrayUnion } from 'firebase/firestore'

import { BASE_URL } from '../config'
import { useParams, useNavigate } from 'react-router-dom'
import ReactGA from 'react-ga4'
import * as amplitude from '@amplitude/analytics-browser'

import { Project, Track, Version, Comment, Permission } from '../_helpers/types'
import ErrorScreen from './common/ErrorScreen'
import LoadingScreen from './common/LoadingScreen'
import Waveform from './Waveform'
import axios, { AxiosError } from 'axios'
import ShareClient from './ShareClient'
import EmailModal from './common/EmailModal'
import { User } from 'firebase/auth'
import MobileAccessErrorScreen from './common/MobileAccessErrorScreen'
import VersionSelector from './VersionSelector'
import { getPermissions, Role } from '../api/permission/permissions'
import RenameVersion from './project/RenameVersion'

const RevisionPage: React.FC<{ setShowSidebar: (show: boolean) => void }> = ({
  setShowSidebar
}) => {
  const [user, loading, error] = useAuthState(auth)
  const { projectID, trackID } = useParams<{
    projectID: string
    trackID: string
  }>()
  const navigate = useNavigate()

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [comments, setComments] = useState<Comment[]>([])
  const [, setCommentsLoading] = useState(true)
  const [newMessage, setNewMessage] = useState<string>('')
  const [aiResponse, setAIResponse] = useState<string[]>([])
  const [aiLoading, setAILoading] = useState<boolean>(false)
  const [lastUserMessage, setLastUserMessage] = useState<string>('')
  const [hasChanges, setHasChanges] = useState(false)
  const [hasProvidedEmailOrSignedIn, setHasProvidedEmailOrSignedIn] =
    useState<boolean>(false)
  const {
    isOpen: isEmailModalOpen,
    onOpen: openEmailModal,
    onClose: closeEmailModal
  } = useDisclosure()
  const commentEndRef = useRef<HTMLDivElement>(null)
  const toast = useToast()

  const [versions, setVersions] = useState<Version[]>([])
  const [versionsLoading, setVersionsLoading] = useState(true)
  const [versionsError, setVersionsError] = useState<Error | null>(null)

  const [track, setTrack] = useState<Track | null>(null)
  const [trackLoading, setTrackLoading] = useState(true)
  const [trackError, setTrackError] = useState<Error | null>(null)

  const [project, setProject] = useState<Project | null>(null)
  const [projectLoading, setProjectLoading] = useState(true)
  const [projectError, setProjectError] = useState<Error | null>(null)

  const [permissions, setPermissions] = useState<Permission[]>([])

  const [menuVersion, setMenuVersion] = useState<Version | null>(null)
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 })

  const {
    isOpen: isRenameVersionOpen,
    onOpen: openRenameVersion,
    onClose: closeRenameVersion
  } = useDisclosure()

  const [waveformKey, setWaveformKey] = useState(0)

  const [showInstructions, setShowInstructions] = useState(false)

  useEffect(() => {
    setShowSidebar(true)
  }, [setShowSidebar])

  const fetchVersions = useCallback(async () => {
    setVersionsLoading(true)
    try {
      const response = await axios.get<Version[]>(
        `${BASE_URL}/versions?trackID=${trackID}`
      )
      // Sort versions: final versions first, then by createdAt date (newest first)
      const sorted = response.data.sort((a, b) => {
        if (a.isFinal !== b.isFinal) {
          return a.isFinal ? -1 : 1
        }
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      })

      setVersions(sorted)
      setVersionsError(null)
    } catch (error) {
      setVersionsError(error as Error)
    } finally {
      setVersionsLoading(false)
    }
  }, [trackID])

  const fetchTrack = useCallback(async () => {
    setTrackLoading(true)
    try {
      const response = await axios.get<Track>(`${BASE_URL}/tracks/${trackID}`)
      setTrack(response.data)
      setTrackError(null)
    } catch (error) {
      setTrackError(error as Error)
    } finally {
      setTrackLoading(false)
    }
  }, [trackID])

  const fetchProject = useCallback(async () => {
    setProjectLoading(true)
    try {
      const response = await axios.get<Project>(`${BASE_URL}/projects/${projectID}`)
      setProject(response.data)
      setProjectError(null)
    } catch (error) {
      setProjectError(error as Error)
    } finally {
      setProjectLoading(false)
    }
  }, [projectID])

  const fetchPermissions = useCallback(async () => {
    if (!projectID || !user?.uid) return
    const permissions = await getPermissions(projectID)
    setPermissions(
      permissions.filter((permission) => permission.email === user?.email) || []
    )
  }, [projectID, user?.uid])

  useEffect(() => {
    fetchVersions()
  }, [fetchVersions])

  useEffect(() => {
    fetchTrack()
  }, [fetchTrack])

  useEffect(() => {
    fetchProject()
  }, [fetchProject])

  useEffect(() => {
    fetchPermissions()
  }, [fetchPermissions])

  const [currentVersionIndex, setCurrentVersionIndex] = useState<number>(
    versions.length > 0 ? versions.findIndex((version) => version.isFinal) : 0
  )
  const [currentVersion, setCurrentVersion] = useState<Version | null>(
    versions.length > 0
      ? versions.find((version) => version.isFinal) || versions[0]
      : null
  )
  
  const [audioUrl, setAudioUrl] = useState<string | null>(null)

  useEffect(() => {
    if (versions.length > 0 && currentVersionIndex < versions.length) {
      setCurrentVersion(versions[currentVersionIndex])
    }
  }, [versions, currentVersionIndex])

  useEffect(() => {
    if (currentVersion && currentVersion.fileID) {
      const pathReference = ref(storage, `audio/${currentVersion.fileID}`)
      getDownloadURL(pathReference)
        .then((url) => {
          setAudioUrl(url)
        })
        .catch((error) => {
          console.error('Error fetching audio:', error)
        })
    }
  }, [currentVersion, currentVersionIndex, versions])

  useEffect(() => {
    let unsubscribe: () => void;

    const fetchAndSubscribeToComments = async () => {
      if (currentVersion?.id) {
        setCommentsLoading(true)
        const versionRef = doc(db, 'versions', currentVersion.id)

        unsubscribe = onSnapshot(versionRef, (docSnapshot) => {
          if (docSnapshot.exists()) {
            const versionData = docSnapshot.data()
            const fetchedComments = versionData.generalComments || []
            setComments(fetchedComments.map((comment: Comment) => ({
              ...comment,
              timestamp: comment.timestamp.toLocaleString()
            })))
          }
          setCommentsLoading(false)
        })
      } else {
        setCommentsLoading(false)
      }
    }

    fetchAndSubscribeToComments()

    return () => {
      console.log('Cleaning up comment listener')
      if (unsubscribe) {
        unsubscribe()
      }
    }
  }, [currentVersion?.id])
  

  const checkEmailInLocalStorage = useCallback(() => {
    const storedEmail = localStorage.getItem('userEmail')
    return !!storedEmail
  }, [])

  useEffect(() => {
    if (user || checkEmailInLocalStorage()) {
      setHasProvidedEmailOrSignedIn(true)
      setShowInstructions(true)
    } else {
      openEmailModal()
    }
  }, [user, checkEmailInLocalStorage, onOpen])

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
      name: 'Landing Page'
    })
  }, [])

  useEffect(() => {
    if (commentEndRef.current && comments.length > 0) {
      commentEndRef.current.scrollIntoView({ behavior: 'smooth' })
      commentEndRef.current.scrollTop = commentEndRef.current?.scrollHeight
    }
  }, [comments])

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const shouldSave = queryParams.get('save')

    if (shouldSave === 'true' && user) {
      handleSaveProject(true)
    }
  }, [user, location])

  const handleSaveProject = async (autoSave = false) => {
    if (!user) {
      // User is not logged in
      const willLogin = window.confirm(
        'You need to be logged in to save this project. Would you like to log in or create an account?'
      )

      if (willLogin) {
        const currentPath = `/projects/${projectID}/${trackID}/${currentVersion?.id}`
        navigate(`/login?returnUrl=${encodeURIComponent(currentPath)}&save=true`)
      }
      return
    }

    try {
      await axios.post(`${BASE_URL}/projects/save`, {
        projectID: projectID,
        userID: user?.uid
      })

      if (!toast.isActive('save-toast')) {
        toast({
          id: 'save-toast',
          title: 'Project saved successfully',
          description: 'The project has been saved to your account.',
          status: 'success',
          duration: 5000,
          isClosable: true
        })
      }

      fetchProject()

      // TODO: Need some way to update the sidebar to show the saved project

      // Remove the 'save' parameter from the URL
      if (autoSave) {
        const newUrl = new URL(window.location.href)
        newUrl.searchParams.delete('save')
        window.history.replaceState({}, '', newUrl.toString())
      }
    } catch (error) {
      const axios_error = error as AxiosError<{ detail: string }>

      console.error('Error saving project:', error)
      if (!toast.isActive('saveerror-toast')) {
        toast({
          id: 'saveerror-toast',
          title: 'Failed to save project',
          description: `An error occurred while saving the project: ${axios_error.response?.data?.detail}`,
          status: 'error',
          duration: 5000,
          isClosable: true
        })
      }
    }
  }

  const handleSendComment = useCallback(async () => {
    if (newMessage.trim() && currentVersion) {
      const commentObj: Comment = {
        content: newMessage,
        sender: user?.email || localStorage.getItem('userEmail') || 'Anonymous',
        timestamp: new Date()
      }

      try {
        const versionRef = doc(db, 'versions', currentVersion.id)
        await updateDoc(versionRef, {
          generalComments: arrayUnion(commentObj)
        })
                
        setNewMessage('')
        setHasChanges(true)

        // Track General Comment Sent
        amplitude.track('General comment sent', {
          userID: user?.uid || 'Invitee'
        })
      } catch (error) {
        console.error('Error sending comment:', error)
        toast({
          title: 'Error sending comment',
          description: 'An error occurred while sending your comment. Please try again.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      }
    }
  }, [newMessage, user, currentVersion, toast])

  const handleAISuggestion = async () => {
    if (newMessage.trim()) {
      setAIResponse([])
      setLastUserMessage(newMessage)
      setAILoading(true)

      try {
        const response = await axios.post(`${BASE_URL}/threads/ai`, {
          file_path: audioUrl,
          message: newMessage
        })

        const rawResp = JSON.parse(response.data)

        // Parse the response (json format) to an array of strings
        const apiResp = rawResp.map((resp: any) => { // eslint-disable-line @typescript-eslint/no-explicit-any
          return resp.message
        })

        setAIResponse([...apiResp])

        // Track the AI suggestion generated
        // TODO: Should we make this more specific (by thread, version, etc.)?
        amplitude.track('AI Suggestion Generated', {
          userID: user?.uid
        })
      } catch (error) {
        console.error('Error generating AI response:', error)
        toast({
          title: 'Failed to generate AI suggestions',
          status: 'error',
          duration: 3000,
          isClosable: true
        })
      } finally {
        setAILoading(false)
      }
    }
  }

  const handleAIUpdate = async (aiMsg: string) => {
    const updatedComments = [
      ...comments,
      {
        content: aiMsg,
        sender: user?.email || localStorage.getItem('userEmail') || 'Anonymous',
        timestamp: new Date()
      }
    ]
    setComments(updatedComments)
    setNewMessage('')
    setAIResponse([])

    try {
      await axios.put(`${BASE_URL}/versions/${currentVersion?.id}`, {
        generalComments: updatedComments.map((comment) => ({
          content: comment.content,
          sender: comment.sender,
          timestamp: comment.timestamp.toLocaleString()
        }))
      })

      // Track the AI suggestion used
      // TODO: Should we make this more specific (by thread, version, etc.)?
      amplitude.track('AI Suggestion Used', {
        userID: user?.uid
      })
    } catch (error) {
      console.error('Error updating comments:', error)
      setComments(comments)
    }
  }

  const handleClose = () => {
    if (hasChanges) {
      fetchVersions()
    }
    setHasChanges(false)
    onClose()
  }

  const bgColor = useColorModeValue('gray.100', 'gray.700')
  const textColor = useColorModeValue('gray.800', 'white')

  const [isVersionSelectorDisabled, setIsVersionSelectorDisabled] = useState(false)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  const handleVersionSelect = useCallback((index: number) => {
    setIsVersionSelectorDisabled(true)
    setCurrentVersionIndex(index)
    // setCurrentVersion(versions[index]);
    setWaveformKey((prevKey) => prevKey + 1)

    // Clear any existing timeout
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    // Set a new timeout to re-enable the selector after 3 seconds
    timeoutRef.current = setTimeout(() => {
      setIsVersionSelectorDisabled(false)
    }, 2000)
  }, [])

  const handleMenuOpen = (version: Version, buttonRect: DOMRect) => {
    setMenuVersion(version)
    setMenuPosition({
      top: buttonRect.bottom,
      left: buttonRect.left
    })
  }

  const handleRenameVersion = () => {
    openRenameVersion()
  }

  const handleDeleteVersion = async () => {
    if (window.confirm(`Are you sure you want to delete this version?`)) {
      setMenuVersion(null)
      await axios.delete(`${BASE_URL}/versions/${currentVersion?.id}`)
      toast({
        title: 'Version deleted',
        description: 'The version has been deleted.',
        status: 'success',
        duration: 3000,
        isClosable: true
      })
      fetchVersions()
    }
  } 

  // Clean up the timeout on component unmount
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  if (loading || versionsLoading || trackLoading || projectLoading || !currentVersion) {
    return <LoadingScreen />
  }

  if (
    error ||
    versionsError ||
    trackError ||
    projectError ||
    !versions ||
    !track ||
    !project
  ) {
    return (
      <ErrorScreen
        error={versionsError || trackError || projectError || new Error('Data not found')}
      />
    )
  }

  const isMobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  }

  if (isMobile()) {
    return <MobileAccessErrorScreen home='/' />
  }

  const isNewOrRecentUser = (user: User | null | undefined): boolean => {
    if (!user) return true

    const now = new Date()
    const lastSignInTime = user.metadata.lastSignInTime
      ? new Date(user.metadata.lastSignInTime)
      : now
    const creationTime = user.metadata.creationTime
      ? new Date(user.metadata.creationTime)
      : now

    const isFirstSignIn = creationTime.getTime() === lastSignInTime.getTime()
    // const isWithinTenMinutes = now.getTime() - lastSignInTime.getTime() <= 10 * 60 * 1000 // 10 minutes in milliseconds

    return isFirstSignIn
  }

  const isOwner =
    (permissions.length > 0 && permissions[0].roles.includes(Role.MANAGE)) ||
    project.userIDs.includes(user?.uid || '')

  const canCreateVersion =
    (permissions.length > 0 && permissions[0].roles.includes(Role.UPLOAD)) || isOwner

  const canComment = permissions.length > 0 || isOwner

  const handleEmailModalClose = () => {
    closeEmailModal()
    setHasProvidedEmailOrSignedIn(true)
    setShowInstructions(true)
  }

  return (
    <>
      <Box color='gray.600' minHeight='100vh' px={10} py={3}>
        <Flex alignItems='center' justifyContent='space-between' mb={4}>
          <VStack align='flex-end' spacing={0}>
            <Tooltip label={project.name} hasArrow>
              <Heading
                as='h1'
                size='lg'
                fontWeight='bold'
                color='gray.700'
                whiteSpace='nowrap'
                overflow='hidden'
                textOverflow='ellipsis'
                maxWidth={window.innerWidth * 0.3}
              >
                {project.name}
              </Heading>
            </Tooltip>
            <Tooltip label={`${track.name} - ${currentVersion?.name}`} hasArrow>
              <Heading
                as='h2'
                fontSize='xl'
                color='gray.500'
                whiteSpace='nowrap'
                overflow='hidden'
                textOverflow='ellipsis'
                maxWidth={window.innerWidth * 0.5}
              >
                {track.name} - {currentVersion?.name}
              </Heading>
            </Tooltip>
          </VStack>

          <HStack spacing={4} mb={4}>
            <CommentLegend />

            <Button
              onClick={onOpen}
              leftIcon={<FaComment />}
              colorScheme='orange'
              variant='outline'
              _hover={{ bg: 'orange.400', color: 'white' }}
            >
              Chat
            </Button>

            {user && (
              <ShareClient
                projectName={project.name}
                projectID={project.id}
                isOwner={isOwner}
              />
            )}

            {project.userIDs &&
              !project.userIDs.some((id: string) => id === user?.uid) && (
                <Button
                  onClick={() => handleSaveProject(false)}
                  leftIcon={<FaSave />}
                  colorScheme='blue'
                  variant='outline'
                  _hover={{ bg: 'blue.400', color: 'white' }}
                >
                  Save Project
                </Button>
              )}
          </HStack>
        </Flex>

        {audioUrl && (
          <Waveform
            key={waveformKey}
            audio={audioUrl}
            versionID={currentVersion.id}
            isComplete={false}
            roles={permissions.length > 0 ? permissions[0].roles : []}
            isOwner={project.userIDs.includes(user?.uid || '')}
          />
        )}

        <VersionSelector
          versions={versions}
          currentVersionIndex={currentVersionIndex}
          onVersionSelect={handleVersionSelect}
          track={track}
          isOwner={isOwner}
          isDisabled={isVersionSelectorDisabled}
          onMenuOpen={handleMenuOpen}
          canCreateVersion={canCreateVersion}
        />

        {/* Chat Drawer */}
        <Drawer isOpen={isOpen} placement='right' onClose={handleClose} size='md'>
          <DrawerOverlay />
          <DrawerContent bg={bgColor}>
            <DrawerCloseButton />
            <DrawerHeader borderBottomWidth='1px'>
              <Heading size='lg' color={textColor}>
                Chat
              </Heading>
              <Text fontSize='sm' color='gray.500'>
                Comments made here are visible to all collaborators
              </Text>
            </DrawerHeader>
            <DrawerBody display='flex' flexDirection='column'>
              <VStack spacing={4} align='stretch' flex={1} overflowY='auto' mb={4}>
                {comments.map((comment, index) => {
                  const isUserComment =
                    comment.sender ===
                    (user?.email || localStorage.getItem('userEmail') || 'Anonymous')

                  return (
                    <Flex
                      key={index} // Use index as key since Comment type doesn't have an id
                      justifyContent={isUserComment ? 'flex-end' : 'flex-start'}
                    >
                      <Box
                        maxWidth='70%'
                        bg={isUserComment ? 'blue.500' : 'gray.200'}
                        color={isUserComment ? 'white' : 'black'}
                        p={3}
                        borderRadius='lg'
                        borderTopRightRadius={isUserComment ? 0 : 'lg'}
                        borderTopLeftRadius={isUserComment ? 'lg' : 0}
                      >
                        {!isUserComment && (
                          <Text fontWeight='bold'>{comment.sender}</Text>
                        )}
                        <Text
                          fontSize='sm'
                          color={isUserComment ? 'blue.100' : 'gray.500'}
                          mb={1}
                        >
                          {comment.timestamp.toLocaleString()}
                        </Text>
                        <Text>{comment.content}</Text>
                      </Box>
                    </Flex>
                  )
                })}
                <div ref={commentEndRef} />
              </VStack>
              {aiLoading && (
                <Spinner
                  thickness='3px'
                  color='orange.500'
                  size='lg'
                  justifySelf='center'
                  alignSelf='center'
                  mb={3}
                />
              )}
              {aiResponse.length !== 0 && (
                <VStack align='stretch' spacing={3}>
                  <Text fontSize='lg' fontWeight='bold'>
                    Select a response that best matches your feedback:
                  </Text>
                  <HStack spacing={4} alignItems='stretch' overflowX='auto' pb={2}>
                    <AIResponseBox
                      response={lastUserMessage}
                      handleAIUpdate={handleAIUpdate}
                      aiGenerated={false}
                    />
                    {aiResponse.map((response, index) => (
                      <AIResponseBox
                        key={index}
                        response={response}
                        handleAIUpdate={handleAIUpdate}
                        aiGenerated={true}
                      />
                    ))}
                  </HStack>
                </VStack>
              )}
              <HStack
                as='form'
                onSubmit={(e: React.FormEvent) => {
                  e.preventDefault()
                  handleSendComment()
                }}
              >
                <Input
                  value={newMessage}
                  isDisabled={!canComment}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setNewMessage(e.target.value)
                  }
                  placeholder={
                    canComment
                      ? 'Type your comment...'
                      : 'You do not have permission to comment'
                  }
                  bg='white'
                  color={textColor}
                />
                <IconButton
                  icon={<FaMagic />}
                  colorScheme='orange'
                  onClick={handleAISuggestion}
                  isDisabled={!newMessage.trim()}
                  aria-label='AI Suggest'
                />
                <IconButton
                  icon={<FaPaperPlane />}
                  colorScheme='blue'
                  isDisabled={!newMessage.trim()}
                  type='submit'
                  aria-label='Send comment'
                />
              </HStack>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
        <EmailModal
          isOpen={isEmailModalOpen && !hasProvidedEmailOrSignedIn}
          onClose={handleEmailModalClose}
          setHasProvidedEmailOrSignedIn={setHasProvidedEmailOrSignedIn}
        />
      </Box>

      <InstructionsModal
        isOpen={showInstructions && isNewOrRecentUser(user)}
        onClose={() => setShowInstructions(false)}
      />

      <Portal>
        <Menu isOpen={menuVersion !== null}>
          <MenuButton
            style={{ position: 'fixed', top: menuPosition.top, left: menuPosition.left }}
          />
          <MenuList>
            <MenuItem onClick={handleRenameVersion}>Rename Version</MenuItem>
            <MenuItem onClick={handleDeleteVersion}>Delete Version</MenuItem>
          </MenuList>
        </Menu>
      </Portal>

      <RenameVersion
        isOpen={isRenameVersionOpen}
        onClose={() => {
          setMenuVersion(null)
          closeRenameVersion()
        }}
        version={menuVersion!} // eslint-disable-line @typescript-eslint/no-non-null-assertion
        // fetchVersions={fetchVersions}
      />
    </>
  )
}

interface AIResponseBoxProps {
  response: string
  handleAIUpdate: (aiMsg: string) => void
  aiGenerated: boolean
}

const AIResponseBox: React.FC<AIResponseBoxProps> = ({
  response,
  handleAIUpdate,
  aiGenerated
}) => {
  const [editResponse, setEditResponse] = useState(response)
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  const handleSaveClick = () => {
    handleAIUpdate(editResponse)
  }

  const textAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEditResponse(e.target.value)
    adjustTextareaHeight()
  }

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current
    if (textarea) {
      textarea.style.height = 'auto'
      textarea.style.height = textarea.scrollHeight + 'px'
    }
  }

  return (
    <Box
      bg={aiGenerated ? 'orange.100' : 'gray.100'}
      p={3}
      borderRadius='lg'
      borderTopLeftRadius={0}
      minWidth='200px'
      maxWidth='300px'
      flexShrink={0}
    >
      <Text fontWeight='bold' mb={1}>
        {aiGenerated ? 'AI Suggestion' : 'Original Comment'}
      </Text>
      <Text fontSize='sm' color='gray.500' mb={2}>
        {new Date().toLocaleString()}
      </Text>
      <Textarea
        ref={textareaRef}
        value={editResponse}
        onChange={textAreaChange}
        onInput={adjustTextareaHeight}
        size='sm'
        variant='filled'
        resize='none'
        minH='100px'
        mb={2}
        bg='white'
      />
      <Flex justifyContent='flex-end'>
        <IconButton
          icon={<Icon as={FaPaperPlane} />}
          size='sm'
          colorScheme='green'
          onClick={handleSaveClick}
          aria-label='Save response'
        />
      </Flex>
    </Box>
  )
}

const CommentLegend: React.FC = () => {
  return (
    <Flex align='center' ml={4} bg='blue.50' p={2} borderRadius='8'>
      <Tooltip label='Resolved: All changes communicated and discussion complete'>
        <Flex align='center' mr={4}>
          <Icon as={FaCheckCircle} color='green.500' mr={1} />
          <Box as='span'>{'Resolved (green box)'}</Box>
        </Flex>
      </Tooltip>
      <Tooltip label='Unresolved: Active communication ongoing'>
        <Flex align='center'>
          <Icon as={FaCommentDots} color='blue' opacity='0.4' mr={1} />
          <Box as='span'>{'Unresolved (blue box)'}</Box>
        </Flex>
      </Tooltip>
    </Flex>
  )
}

const InstructionsModal: React.FC<{ isOpen: boolean; onClose: () => void }> = ({
  isOpen,
  onClose
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent color='orange.600'>
        <ModalHeader color='black'>Waveform Controls</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack align='start' spacing={4}>
            <Text>
              <strong style={{ color: 'black' }}>Create a Thread:</strong> Click and drag
              to create a new comment thread
            </Text>
            <Text>
              <strong style={{ color: 'black' }}>Open a Thread:</strong> Double click on
              any highlighted region to open it
            </Text>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button leftIcon={<FaPaperPlane />} colorScheme='orange' onClick={onClose}>
            Get Started
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default RevisionPage
