import React from 'react';
import {
  ChakraProvider,
  Box,
  VStack,
  HStack,
  Text,
  Button,
  List,
  ListItem,
  ListIcon,
  useColorModeValue,
  extendTheme,
  Flex,
  Badge,
  Container,
  Heading,
  Image,
  Grid,
  GridItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Tooltip
} from '@chakra-ui/react';
import { CheckIcon, InfoIcon } from '@chakra-ui/icons';
import { FaMagic, FaUserCircle, FaFileAudio, FaBars, FaComment, FaCheck } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import Footer from './common/Footer';

// Define types for our theme
type ThemeColors = {
  [key: string]: {
    [key: number]: string
  }
}

const themeColors: ThemeColors = {
  brand: {
    50: '#FFF5E6',
    100: '#FFE0B2',
    200: '#FFCC80',
    300: '#FFB74D',
    400: '#FFA726',
    500: '#FF9800',
    600: '#FB8C00',
    700: '#F57C00',
    800: '#EF6C00',
    900: '#E65100'
  }
}

const theme = extendTheme({
  colors: themeColors,
  fonts: {
    heading: '"Poppins", sans-serif',
    body: '"Inter", sans-serif'
  }
})

const PricingPage = () => {
  const navigate = useNavigate();
  const boxBgColor = useColorModeValue('white', 'gray.700');
  const { isOpen: isLiteOpen, onOpen: onLiteOpen, onClose: onLiteClose } = useDisclosure();
  const { isOpen: isPremiumOpen, onOpen: onPremiumOpen, onClose: onPremiumClose } = useDisclosure();

  const plans = [
    {
      name: "Waveform Starter",
      price: { monthly: "Free" },
      features: [
        "Unlimited Lite Projects",
        "Up to 5GB Data",
      ],
      audience: "For Aspiring Audio Creators",
      cta: "Start Free"
    },
    {
      name: "Audio Pro",
      price: { monthly: 12.99 },
      features: [
        "Unlimited Premium Projects",
        "Up to 50GB Data",
      ],
      audience: "For Seasoned Professionals",
      cta: "Get Pro"
    },
    {
      name: "Enterprise",
      price: { monthly: "Custom" },
      features: [
        "Custom Features",
        "Custom Requirements",
        "Custom Integrations",
      ],
      audience: "For Studios and Institutions",
      cta: "Request a Quote"
    }
  ];

  type ProjectInfoModalProps = {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    features: string[];
  };

  const ProjectInfoModal: React.FC<ProjectInfoModalProps> = ({ isOpen, onClose, title, features }) => {
    const getIconForFeature = (feature: string) => {
      if (feature.includes("Timestamps")) return FaComment;
      if (feature.includes("AI Suggestions")) return FaMagic;
      if (feature.includes("Share")) return FaUserCircle;
      if (feature.includes("Track") && !feature.includes("Versions")) return FaFileAudio;
      if (feature.includes("Versions")) return FaBars;
      return FaCheck; // Default icon
    };

    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent py = {4}>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <List spacing={4}>
              {features.map((feature, index) => (
                <ListItem key={index} display="flex" alignItems="center">
                  <ListIcon as={getIconForFeature(feature)} color="orange.300" boxSize={5} />
                  <Text ml={2}>{feature}</Text>
                </ListItem>
              ))}
            </List>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  };

  return (
    <ChakraProvider theme={theme}>
      <Box minHeight="100vh" bg={'orange.100'}>
        {/* Navbar */}
        <Box as='nav' bg='white' boxShadow='sm' position='sticky' top={0} zIndex={10}>
          <Flex mx='auto' px={4} align='center' justify='space-between'>
            <Flex align='center'>
              <Image src='/logo.png' alt='Soloflow Logo' height='60px'/>
            </Flex>
            <HStack>
              <Button variant='ghost' onClick={() => navigate('/login')}>Log in</Button>
              <Button colorScheme='brand' onClick={() => navigate('/signup')}>Sign up</Button>
            </HStack>
          </Flex>
        </Box>

        <Container maxW="container.xl" py={20}>
          <VStack spacing={12} align="center">
            <VStack spacing={4}>
              <Heading as="h1" size="2xl" textAlign="center">Choose Your Plan</Heading>
            </VStack>

            <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }} gap={8}>
              {plans.map((plan, index) => (
                <GridItem key={plan.name}>
                  <Box bg={boxBgColor} p={8} borderRadius="lg" boxShadow="md" height="100%" position="relative" display="flex" flexDirection="column">
                    {index === 1 && (
                      <Badge bg="orange.500" color='white' position="absolute" top="-3" right="-3" px={3} py={1} borderRadius="full">
                        Most Popular
                      </Badge>
                    )}
                    <VStack align="start" spacing={2} height="100%">
                      <Text fontSize="3xl" fontWeight="bold">{plan.name}</Text>
                      <HStack>
                        <Text fontSize="xl" fontWeight="bold">
                          {typeof plan.price.monthly === 'number' ? `$${plan.price.monthly} /mo` : plan.price.monthly}
                        </Text>
                        {index === 0 && (
                          <Text fontSize="sm" color="gray.500" alignSelf={'flex-end'}>
                            *No credit card required
                          </Text>
                        )}
                      </HStack>
                      
                      <Box borderColor="orange.500" borderWidth={2} color="orange.500" p={2} borderRadius="md" w="full" textAlign="center" fontWeight={'medium'} fontSize={'lg'} mt={2}>
                        {plan.audience}
                      </Box>

                      <List spacing={3} flex={1} mt={4}>
                        {plan.features.map((feature, i) => (
                          <ListItem key={i} fontWeight={"normal"} color={"black"} fontSize={"md"}>
                            <ListIcon as={CheckIcon} color="green.500" />
                            {feature}
                            {(feature.includes("Lite Projects") || feature.includes("Premium Projects")) && (
                              <Tooltip label="Click to learn the specifications" placement="right">
                                <InfoIcon 
                                  ml={2} 
                                  color="orange.500" 
                                  cursor="pointer"
                                  onClick={feature.includes("Lite") ? onLiteOpen : onPremiumOpen}
                                />
                              </Tooltip>
                            )}
                          </ListItem>
                        ))}
                      </List>
                      {index === 0 && (
                        <Text fontSize="sm" textAlign="left" mt={8} color = 'red' fontWeight={'bold'}>
                          Hurry! Get started now and receive 2 Premium Projects for free
                        </Text>
                      )}
                      {index === 2 && (
                        <Text fontSize="sm" textAlign="center" mt={8}>
                          * We&rsquo;ll get back within 24 hours
                        </Text>
                      )}
                      <Button
                        as={motion.button}
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        colorScheme="orange"
                        size="lg"
                        w="full"
                      >
                        {plan.cta}
                      </Button>
                    </VStack>
                  </Box>
                </GridItem>
              ))}
            </Grid>
          </VStack>
        </Container>
      </Box>
      <Footer/>

      <ProjectInfoModal
        isOpen={isLiteOpen}
        onClose={onLiteClose}
        title="Lite Projects"
        features={[
          "Unlimited Timestamps",
          "Unlimited AI Suggestions",
          "Share w/ up to 3 Users",
          "1 Track",
          "3 Versions per Track"
        ]}
      />

      <ProjectInfoModal
        isOpen={isPremiumOpen}
        onClose={onPremiumClose}
        title="Premium Projects"
        features={[
          "Unlimited Timestamps",
          "Unlimited AI Suggestions",
          "Share w/ Unlimited Users",
          "Unlimited Tracks",
          "Unlimited Versions"
        ]}
      />
    </ChakraProvider>
  );
};

export default PricingPage;