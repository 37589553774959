import React, { useState, useEffect } from 'react'
import { motion, useAnimation } from 'framer-motion'
import {
  ChakraProvider,
  extendTheme,
  Box,
  Flex,
  Heading,
  Text,
  Button,
  Stack,
  Input,
  Textarea,
  useToast,
  HStack,
  Link,
  Image,
} from '@chakra-ui/react'
import FeatureTabs from './home/FeatureTabs'
import HeroSection from './home/HeroSection'
import ReviewsPane from './home/ReviewsPane'
import { useNavigate } from 'react-router-dom'
import Footer from './common/Footer'
import emailjs from '@emailjs/browser'
import AnimatedSection from './home/AnimatedSection'

// Define types for our theme
type ThemeColors = {
  [key: string]: {
    [key: number]: string
  }
}

const themeColors: ThemeColors = {
  brand: {
    50: '#FFF5E6',
    100: '#FFE0B2',
    200: '#FFCC80',
    300: '#FFB74D',
    400: '#FFA726',
    500: '#FF9800',
    600: '#FB8C00',
    700: '#F57C00',
    800: '#EF6C00',
    900: '#E65100'
  }
}

const theme = extendTheme({
  colors: themeColors,
  fonts: {
    heading: '"Poppins", sans-serif',
    body: '"Inter", sans-serif'
  }
})

interface LandingPageProps {
  setShowSidebar: (showSidebar: boolean) => void
}

const LandingPage: React.FC<LandingPageProps> = ({ setShowSidebar }) => {
  const [currentWord, setCurrentWord] = useState<number>(0)
  const navigate = useNavigate()

  const words: string[] = ['Collaborate', 'Create', 'Innovate', 'Succeed']
  const controls = useAnimation()
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  })

  useEffect(() => {
    setShowSidebar(false)
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWord((prev) => (prev + 1) % words.length)
    }, 2000)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    controls.start({
      opacity: [0, 1],
      y: [20, 0],
      transition: { duration: 0.5 }
    })
  }, [currentWord, controls])

  const toast = useToast()

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const templateParams = {
      name: formData.name,
      email: formData.email,
      source: 'Landing Page',
      feedback: formData.message
    }
    // TODO: setup backend api endpoint to send email
    const serviceID = 'service_5kz2csq'
    const templateID = 'template_h40uzem'
    const userPublicKey = 'Th0mry8sH3us0Uv05'
    emailjs.send(serviceID, templateID, templateParams, {
      publicKey: userPublicKey
    })

    toast({
      title: 'Feedback sent successfully',
      description: 'Thank you for your feedback!',
      status: 'success',
      duration: 5000,
      isClosable: true
    })
    setFormData({ name: '', email: '', message: '' })
  }

  return (
    <ChakraProvider theme={theme}>
      <Box>
        {/* Navbar */}
        <Box as='nav' bg='white' boxShadow='sm' position='sticky' top={0} zIndex={10}>
          <Flex
            mx='auto'
            px={4}
            align='center'
            justify='space-between'
          >
            <Flex align='center'>
              <Image src='/logo.png' alt='Soloflow Logo' height='60px'/>
            </Flex>
            <HStack spacing={8} display={{ base: 'none', md: 'flex' }}>
              <Link href='#features'>Features</Link>
              <Link href={`/pricing`}>Pricing</Link>
              <Link href='#contact'>Contact</Link>
            </HStack>
            <HStack>
              <Button variant='ghost' onClick={() => navigate('/login')}>
                Log in
              </Button>
              <Button colorScheme='brand' onClick={() => navigate('/signup')}>
                Sign up
              </Button>
            </HStack>
          </Flex>
        </Box>

        {/* Hero Section */}
        <AnimatedSection>
          <HeroSection />
        </AnimatedSection>

        {/* Reviews Pane*/}
        <AnimatedSection>
          <ReviewsPane />
        </AnimatedSection>

        {/* Features Section */}
        <Box id = 'features' />
        <AnimatedSection>
          <FeatureTabs/>
        </AnimatedSection>

        {/* CTA Section */}
        <AnimatedSection>
          <Box py={20} bg='brand.500' color='white'>
            <Flex
              direction='column'
              align='center'
              maxW='1000px'
              mx='auto'
              textAlign='center'
            >
              <Heading as='h2' size='2xl' mb={8}>
                Ready to Elevate Your Audio Projects?
              </Heading>
              <Text fontSize='xl' mb={2}>
                Spend more time on your craft. Waste less time on communication hell.
              </Text>
              <Text fontSize='xl' mb={8} fontWeight='bold'>
                Join hundreds of audio professionals now!
              </Text>
              <Button
                as={motion.button}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                size='lg'
                colorScheme='brand'
                fontWeight='bold'
                px={8}
                bg='white'
                color='brand.700'
                _hover={{
                  bg: 'brand.700',
                  color: 'white'
                }}
                onClick={() => navigate('/login')}
              >
                Start Your Free Trial
              </Button>
            </Flex>
          </Box>
        </AnimatedSection>

        {/* Contact Form */}
        <AnimatedSection>
          <Box py={20} id='contact'>
            <Heading as='h2' size='2xl' textAlign='center' mb={16}>
              Contact Us
            </Heading>
            <Box maxW='600px' mx='auto'>
              <form onSubmit={handleSubmit}>
                <Stack spacing={6}>
                  <Input
                    placeholder='Your Name'
                    size='lg'
                    value={formData.name}
                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  />
                  <Input
                    placeholder='Your Email'
                    size='lg'
                    type='email'
                    value={formData.email}
                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  />
                  <Textarea
                    placeholder='Your Message'
                    size='lg'
                    rows={6}
                    value={formData.message}
                    onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                  />
                  <Button
                    type='submit'
                    colorScheme='brand'
                    size='lg'
                    fontWeight='bold'
                    as={motion.button}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    Send Message
                  </Button>
                </Stack>
              </form>
            </Box>
          </Box>
        </AnimatedSection>
      </Box>
      <Footer />
    </ChakraProvider>
  )
}

export default LandingPage