import React, { useState, useEffect } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  VStack,
  Text,
  Box,
  Spinner,
  Flex,
  useToast,
  Tooltip,
  Icon,
  IconButton
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { doc, query, collection, where, onSnapshot, getDoc, updateDoc, Timestamp } from 'firebase/firestore'
import { db, auth } from '../../firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import { ViewIcon, ChatIcon } from '@chakra-ui/icons'

interface Notification {
  id: string
  versionID: string
  threadId: string
  read: boolean
  createdAt: Timestamp
  recipientID: string
  metaData: {senderEmail: string, projectName: string, trackName: string}
}

interface NotificationsModalProps {
  isOpen: boolean
  onClose: () => void
}

const NotificationsModal: React.FC<NotificationsModalProps> = ({
  isOpen,
  onClose,
}) => {
  const navigate = useNavigate()
  const [, setActiveTab] = useState<'unread' | 'all'>('unread')
  const [notifications, setNotifications] = useState<Notification[]>([])
  const [user] = useAuthState(auth)
  const [loading, setLoading] = useState(false)
  const toast = useToast()

  useEffect(() => {
    if (user) {
      const notificationsRef = collection(db, 'notifications')
      const q = query(notificationsRef, where('recipientId', '==', user.uid))
      
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const notificationsData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as Notification[]
        setNotifications(notificationsData)
      })

      return () => unsubscribe()
    }
  }, [user])

  const formatTimeAgo = (timestamp: Timestamp) => {
    const now = new Date().getTime()
    const date = timestamp.toDate().getTime()
    const diff = Math.floor((now - date) / 1000) // difference in seconds

    if (diff < 60) return `${diff} seconds ago`
    if (diff < 3600) return `${Math.floor(diff / 60)} minutes ago`
    if (diff < 86400) return `${Math.floor(diff / 3600)} hours ago`
    return `${Math.floor(diff / 86400)} days ago`
  }
  
  const handleNotificationClick = async (notification: Notification) => {
    setLoading(true)
    try {
      const threadRef = doc(db, 'threads', notification.threadId)
      const threadDoc = await getDoc(threadRef)
      console.log('threadDoc', threadDoc)
      
      if (threadDoc.exists()) {
        const threadData = threadDoc.data()
        const versionRef = doc(db, 'versions', threadData.versionID)
        const versionDoc = await getDoc(versionRef)
        
        if (versionDoc.exists()) {
          const versionData = versionDoc.data()
          const trackRef = doc(db, 'tracks', versionData.trackID)
          const trackDoc = await getDoc(trackRef)
          
          if (trackDoc.exists()) {
            const trackData = trackDoc.data()
            navigate(`/projects/${trackData.projectID}/${versionData.trackID}`, { state: { threadId: notification.threadId } })
          } else {
            console.error('Track document does not exist')
          }
        } else {
          console.error('Version document does not exist')
        }
      } else {
        console.error('Thread document does not exist')
        
        toast({
          title: 'Comment not found.',
          description: 'The comment does not exist anymore.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    } catch (error) {
      console.error('Error handling notification click:', error)
    } finally {
      setLoading(false)
      onClose()
    }
  }

  const markAsRead = async (notification: Notification) => {
    try {
      const notificationRef = doc(db, 'notifications', notification.id)
      await updateDoc(notificationRef, { read: true })
    } catch (error) {
      console.error('Error marking notification as read:', error)
    }
  }
  
  const unreadNotifications = notifications.filter(n => !n.read)

  const renderNotificationList = (notificationList: Notification[]) => {
    if (loading) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" height="200px">
          <Spinner />
        </Box>
      )
    }

    return (
      <VStack spacing={4} align="stretch">
        {notificationList.length === 0 ? (
          <Text>No notifications</Text>
        ) : (
          notificationList
            .sort((a, b) => b.createdAt.toMillis() - a.createdAt.toMillis())
            .map((notification) => (
        <Box
          key={notification.id}
          p={4}
          borderWidth={1}
          borderRadius="md"
          bg={notification.read ? 'orange.50' : 'orange.100'}
              >
              <Flex justifyContent="space-between" alignItems="flex-start">
                <Box maxW={'75%'}>
                    <Text fontWeight="bold" isTruncated>New Message From {notification.metaData?.senderEmail || "loading..."}</Text>
                    <Text fontSize="sm" isTruncated>Project: {notification.metaData?.projectName || "loading..."}</Text>
                    <Text fontSize="sm" isTruncated>Track: {notification.metaData?.trackName || "loading..."}</Text>
                </Box>
                <Flex>
                    {!notification.read && (
                    <Tooltip label="Mark as read" hasArrow>
                      <IconButton
                      aria-label="Mark as read"
                      icon={<Icon as={ViewIcon} />}
                      size="sm"
                      colorScheme="blue"
                      mr={2}
                      onClick={(e) => {
                        e.stopPropagation();
                        markAsRead(notification);
                      }}
                      />
                    </Tooltip>
                    )}
                  <Tooltip label="View comment" hasArrow>
                    <IconButton
                      aria-label="View comment"
                      icon={<Icon as={ChatIcon} />}
                      size="sm"
                      colorScheme="orange"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleNotificationClick(notification);
                      }}
                    />
                  </Tooltip>
                </Flex>
              </Flex>
              <Text fontSize="sm" color="gray.500">
                {formatTimeAgo(notification.createdAt)}
              </Text>
            </Box>
          ))
        )}
      </VStack>
    )
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Notifications</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Tabs isFitted variant="enclosed" onChange={(index) => setActiveTab(index === 0 ? 'unread' : 'all')}>
            <TabList mb="1em">
              <Tab>Unread ({unreadNotifications.length})</Tab>
              <Tab>All ({notifications.length})</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                {renderNotificationList(unreadNotifications)}
              </TabPanel>
              <TabPanel>
                {renderNotificationList(notifications)}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default NotificationsModal