import React, { useState, useEffect } from 'react'
import {
  Button,
  HStack,
  VStack,
  Popover,
  PopoverTrigger,
  Portal,
  PopoverContent,
  PopoverCloseButton,
  PopoverBody,
  Text,
  Input,
  Switch,
  useToast,
  Heading,
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  Flex,
  Tooltip,
  Divider,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Avatar
} from '@chakra-ui/react'
import { CloseIcon, LinkIcon, SettingsIcon } from '@chakra-ui/icons'
import emailjs from '@emailjs/browser'
import { useAuthState } from 'react-firebase-hooks/auth'
import * as amplitude from '@amplitude/analytics-browser'

import { auth } from '../firebase'
import { updatePermissions, Role, getPermissions } from '../api/permission/permissions'
import { Permission } from '../_helpers/types'

interface ShareClientProps {
  projectName: string
  projectID: string
  isOwner: boolean
}

interface Invitee {
  email: string
  canComment: boolean
  canUpload: boolean
  canDownload: boolean
  canManage: boolean
}

const ShareClient: React.FC<ShareClientProps> = (props) => {
  const toast = useToast()
  const [user] = useAuthState(auth)
  const [invitees, setInvitees] = useState<Invitee[]>([])
  const [email, setEmail] = useState('')
  const [permissions, setPermissions] = useState({
    canComment: false,
    canUpload: false,
    canDownload: false,
    canManage: false
  })

  const [collaborators, setCollaborators] = useState<Permission[]>([])

  useEffect(() => {
    const fetchCollaborators = async () => {
      try {
        const permissions = await getPermissions(props.projectID)
        setCollaborators(permissions)
      } catch (error) {
        console.error('Error fetching collaborators:', error)
      }
    }

    fetchCollaborators()
  }, [props.projectID])

  const handleAddInvitee = (e: React.FormEvent) => {
    e.preventDefault()
    if (email) {
      setInvitees([...invitees, { email, ...permissions }])
      setEmail('')
      setPermissions({
        canComment: false,
        canUpload: false,
        canDownload: false,
        canManage: false
      })
    }
  }

  const handleRemoveInvitee = (email: string) => {
    setInvitees(invitees.filter((invitee) => invitee.email !== email))
  }

  const handleUpdatePermissions = (email: string, permission: keyof Invitee) => {
    if (permission === 'canManage') {
      setInvitees(
        invitees.map((invitee) =>
          invitee.email === email
            ? {
                ...invitee,
                canComment: !invitee.canManage,
                canUpload: !invitee.canManage,
                canDownload: !invitee.canManage,
                canManage: !invitee.canManage
              }
            : invitee
        )
      )
      return
    }

    setInvitees(
      invitees.map((invitee) =>
        invitee.email === email
          ? { ...invitee, [permission]: !invitee[permission] }
          : invitee
      )
    )
  }

  const handleCopyLink = () => {
    const linkToCopy = window.location.href
    navigator.clipboard.writeText(linkToCopy)
    toast({
      title: 'Share Link Copied',
      description: 'The share link has been copied to your clipboard!',
      status: 'success',
      duration: 3000,
      isClosable: true
    })

    // Track Share Link Copied event
    amplitude.track('Share Link Copied', {
      projectID: props.projectID,
      userID: user?.uid
    })
  }

  const sendEmail = () => {
    try {
      const serviceId = 'service_5kz2csq'
      const templateId = 'template_31n1mzn'
      const userPublicKey = 'Th0mry8sH3us0Uv05'

      // update permissions in the database for each invitee
      invitees.forEach((invitee) => {
        const payload = {
          projectID: props.projectID,
          email: invitee.email,
          roles: [
            invitee.canComment ? Role.COMMENT : null,
            invitee.canUpload ? Role.UPLOAD : null,
            invitee.canDownload ? Role.DOWNLOAD : null,
            invitee.canManage ? Role.MANAGE : null
          ].filter(Boolean) as Role[]
        }

        updatePermissions(payload)
      })

      invitees.forEach((invitee) => {
        // TODO: retry sending email if it fails
        emailjs.send(
          serviceId,
          templateId,
          {
            projectName: props.projectName,
            freelancerName: user?.displayName,
            clientLink: `${window.location.href}`,
            clientEmail: invitee.email
          },
          {
            publicKey: userPublicKey
          }
        )

        amplitude.track('Project Shared', {
          projectID: props.projectID,
          userID: user?.uid
        })
      })

      toast({
        title: 'Email Sent Successfully!',
        description: 'Your project has been shared!',
        status: 'success',
        duration: 5000,
        isClosable: true
      })
    } catch (error) {
      toast({
        title: 'Error',
        description: 'There was an error sending your email. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }
  }

  const handleClose = () => {
    setInvitees([])
    setEmail('')
    setPermissions({
      canComment: false,
      canUpload: false,
      canDownload: false,
      canManage: false
    })
  }

  return (
    <HStack>
      <Popover onClose={handleClose}>
        <PopoverTrigger>
          <Button
            colorScheme='orange'
            fontSize='lg'
            variant='outline'
            _hover={{ color: 'white', bg: 'orange.500' }}
            _active={{ color: 'white', bg: 'orange.500' }}
            size='md'
          >
            Share
          </Button>
        </PopoverTrigger>
        <Portal>
          <PopoverContent width='400px'>
            <PopoverCloseButton />
            <PopoverBody>
              <Tabs>
                <TabList>
                  <Tab>Invite</Tab>
                  <Tab>Share Link</Tab>
                  <Tab>Collaborators</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <Heading as='h3' mt={1} mb={3} size='sm' textAlign='center'>
                      Invite users to collaborate!
                    </Heading>
                    <form onSubmit={handleAddInvitee}>
                      <Text marginBottom='5px' color='gray.600'>
                        Collaborator Email
                      </Text>
                      <Input
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder='Enter email'
                        mb={2}
                      />
                      {props.isOwner && (
                        <VStack align='stretch' spacing={2} mb={2}>
                          <Flex justify='space-between'>
                            <Text>Can comment</Text>
                            <Switch
                              isChecked={permissions.canComment}
                              isDisabled={permissions.canManage}
                              onChange={(e) =>
                                setPermissions({
                                  ...permissions,
                                  canComment: e.target.checked
                                })
                              }
                              colorScheme='orange'
                            />
                          </Flex>
                          <Flex justify='space-between'>
                            <Text>Can upload</Text>
                            <Switch
                              isChecked={permissions.canUpload}
                              isDisabled={permissions.canManage}
                              onChange={(e) =>
                                setPermissions({
                                  ...permissions,
                                  canUpload: e.target.checked
                                })
                              }
                              colorScheme='orange'
                            />
                          </Flex>
                          <Flex justify='space-between'>
                            <Text>Can download</Text>
                            <Switch
                              isChecked={permissions.canDownload}
                              isDisabled={permissions.canManage}
                              onChange={(e) =>
                                setPermissions({
                                  ...permissions,
                                  canDownload: e.target.checked
                                })
                              }
                              colorScheme='orange'
                            />
                          </Flex>
                          <Divider />
                          <Flex justify='space-between'>
                            <Text>Make Owner</Text>
                            <Switch
                              isChecked={permissions.canManage}
                              onChange={(e) =>
                                setPermissions({
                                  canComment: e.target.checked,
                                  canDownload: e.target.checked,
                                  canUpload: e.target.checked,
                                  canManage: e.target.checked
                                })
                              }
                              colorScheme='orange'
                            />
                          </Flex>
                        </VStack>
                      )}
                      <Button type='submit' colorScheme='orange' size='sm' mt={2}>
                        Add Invitee
                      </Button>
                    </form>
                    <VStack mt={4} align='stretch' spacing={2}>
                      {invitees.map((invitee) => (
                        <Box
                          key={invitee.email}
                          p={2}
                          borderWidth={1}
                          borderRadius='md'
                          display='flex'
                          justifyContent='space-between'
                          alignItems='center'
                        >
                          <Text>{invitee.email}</Text>
                          <HStack>
                            <Menu closeOnSelect={false}>
                              <MenuButton
                                as={IconButton}
                                aria-label='Options'
                                icon={<Icon as={SettingsIcon} />}
                                variant='ghost'
                                size='sm'
                              />
                              <MenuList>
                                <MenuItem>
                                  <Flex justify='space-between' width='100%'>
                                    <Text>Can comment</Text>
                                    <Switch
                                      isChecked={invitee.canComment}
                                      isDisabled={invitee.canManage}
                                      onChange={() =>
                                        handleUpdatePermissions(
                                          invitee.email,
                                          'canComment'
                                        )
                                      }
                                      colorScheme='orange'
                                    />
                                  </Flex>
                                </MenuItem>
                                <MenuItem>
                                  <Flex justify='space-between' width='100%'>
                                    <Text>Can upload</Text>
                                    <Switch
                                      isChecked={invitee.canUpload}
                                      isDisabled={invitee.canManage}
                                      onChange={() =>
                                        handleUpdatePermissions(
                                          invitee.email,
                                          'canUpload'
                                        )
                                      }
                                      colorScheme='orange'
                                    />
                                  </Flex>
                                </MenuItem>
                                <MenuItem>
                                  <Flex justify='space-between' width='100%'>
                                    <Text>Can download</Text>
                                    <Switch
                                      isChecked={invitee.canDownload}
                                      isDisabled={invitee.canManage}
                                      onChange={() =>
                                        handleUpdatePermissions(
                                          invitee.email,
                                          'canDownload'
                                        )
                                      }
                                      colorScheme='orange'
                                    />
                                  </Flex>
                                </MenuItem>
                                <MenuItem>
                                  <Flex justify='space-between' width='100%'>
                                    <Text>Can manage</Text>
                                    <Switch
                                      isChecked={invitee.canManage}
                                      onChange={() =>
                                        handleUpdatePermissions(
                                          invitee.email,
                                          'canManage'
                                        )
                                      }
                                      colorScheme='orange'
                                    />
                                  </Flex>
                                </MenuItem>
                              </MenuList>
                            </Menu>
                            <IconButton
                              aria-label='Remove invitee'
                              icon={<Icon as={CloseIcon} />}
                              onClick={() => handleRemoveInvitee(invitee.email)}
                              variant='ghost'
                              size='sm'
                            />
                          </HStack>
                        </Box>
                      ))}
                    </VStack>
                    <Button
                      onClick={sendEmail}
                      colorScheme='orange'
                      fontSize='md'
                      variant='ghost'
                      _hover={{ color: 'white', bg: 'orange.400' }}
                      _active={{ color: 'white', bg: 'orange.400' }}
                      size='sm'
                      isDisabled={invitees.length === 0}
                      my={2}
                    >
                      Send Invites
                    </Button>
                  </TabPanel>
                  <TabPanel>
                    <Heading as='h3' mt={1} mb={3} size='sm' textAlign='center'>
                      Share Link (View Only)
                    </Heading>
                    <Tooltip label='Copy Share Link' aria-label='Copy Share Link'>
                      <Button
                        colorScheme='orange'
                        variant='outline'
                        _hover={{ color: 'white', bg: 'orange.400' }}
                        size='sm'
                        onClick={handleCopyLink}
                        leftIcon={<LinkIcon />}
                        width='100%'
                      >
                        Copy Link
                      </Button>
                    </Tooltip>
                  </TabPanel>
                  <TabPanel>
                    {collaborators.map((collaborator, index) => (
                      <HStack key={index} spacing={3}>
                        <Avatar name={collaborator.email} size='sm' />
                        <Box>
                          <Text fontWeight='bold' fontSize='sm'>
                            {collaborator.email}
                          </Text>
                          <Text fontSize='xs' color='gray.600'>
                            Roles: {collaborator.roles.join(', ')}
                          </Text>
                        </Box>
                      </HStack>
                    ))}
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    </HStack>
  )
}

export default ShareClient
