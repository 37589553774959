import React, { useState, useEffect, useCallback } from 'react';
import { Box, Text, VStack, HStack, IconButton, Avatar } from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const reviews = [
  {
    quote: "Soloflow has completely transformed how I collaborate with publishers and other producers. It's a game-changer!",
    name: "Alex Johnson",
    role: "Music Producer",
    avatar: '/alex_stock.jpg',
  },
  {
    quote: "The communication and feedback system in Soloflow has cut our revision time in half. It's let me take on more projects than ever before.",
    name: "Sarah Lee",
    role: "Sound Engineer",
    avatar: '/sarah_stock.jpg',
  },
  {
    quote: "As an independent artist, Soloflow has made it possible for me to work with professionals around the world.",
    name: "Alejandro Flores",
    role: "Indie Musician",
    avatar: '/alejandro_stock.jpg',
  }
];

const ReviewsCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAutoPlaying, setIsAutoPlaying] = useState(true);

  const nextReview = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % reviews.length);
  }, []);

  const prevReview = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + reviews.length) % reviews.length);
  }, []);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (isAutoPlaying) {
      interval = setInterval(() => {
        nextReview();
      }, 3500);
    }
    return () => clearInterval(interval);
  }, [isAutoPlaying, nextReview]);

  const handleManualNavigation = (direction: 'next' | 'prev') => {
    setIsAutoPlaying(false);
    if (direction === 'next') {
      nextReview();
    } else {
      prevReview();
    }
    // Resume auto-playing after 10 seconds of inactivity
    setTimeout(() => setIsAutoPlaying(true), 10000);
  };

  return (
    <Box 
      position="relative" 
      bgGradient="linear(to-b, #FFE0B2, #FFF5E6)"
      p={8}
      borderColor="brand.50"
    >
      <HStack spacing={4} justifyContent="space-between" alignItems="center">
        <IconButton
          icon={<FaChevronLeft />}
          onClick={() => handleManualNavigation('prev')}
          aria-label="Previous review"
          variant="ghost"
          color="orange.500"
          _hover={{ bg: 'orange.100' }}
        />
        <AnimatePresence>
          <HStack
            key={currentIndex}
            as={motion.div}
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -50 }}
            width="60%"
            p={6}
            bg="white"
            borderRadius="lg"
            boxShadow="md"
          >
            <Avatar 
              mr={4}
              size="xl" 
              src={reviews[currentIndex].avatar} 
              name={reviews[currentIndex].name}
            />
            <VStack align="start" spacing={4}>
              <Text fontStyle="italic" fontSize="md">
                {reviews[currentIndex].quote}
              </Text>
              <VStack align="start" spacing={0}>
                <Text fontWeight="bold" fontSize="sm">
                  {reviews[currentIndex].name}
                </Text>
                <Text fontSize="xs" color="gray.600">
                  {reviews[currentIndex].role}
                </Text>
              </VStack>
            </VStack>
          </HStack>
        </AnimatePresence>
        <IconButton
          icon={<FaChevronRight />}
          onClick={() => handleManualNavigation('next')}
          aria-label="Next review"
          variant="ghost"
          color="orange.500"
          _hover={{ bg: 'orange.100' }}
        />
      </HStack>
    </Box>
  );
};

export default ReviewsCarousel;